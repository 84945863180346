<template>
  <div>
    <button type="button" :class="$style.title_button" @click="showContent = !showContent">
      <div>
        <slot name="title" />
      </div>
      <div :class="$style.icon">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
          <template v-if="icon === 'arrow'">
            <path
              v-if="showContent"
              d="M480-528 324-372q-11 11-28 11t-28-11q-11-11-11-28t11-28l184-184q12-12 28-12t28 12l184 184q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-528Z"
            />
            <path
              v-else
              d="M480-361q-8 0-15-2.5t-13-8.5L268-556q-11-11-11-28t11-28q11-11 28-11t28 11l156 156 156-156q11-11 28-11t28 11q11 11 11 28t-11 28L508-372q-6 6-13 8.5t-15 2.5Z"
            />
          </template>
          <template v-else-if="icon === 'plus-minus'">
            <path
              v-if="showContent"
              d="M240-440q-17 0-28.5-11.5T200-480q0-17 11.5-28.5T240-520h480q17 0 28.5 11.5T760-480q0 17-11.5 28.5T720-440H240Z"
            />
            <path
              v-else
              d="M440-440H240q-17 0-28.5-11.5T200-480q0-17 11.5-28.5T240-520h200v-200q0-17 11.5-28.5T480-760q17 0 28.5 11.5T520-720v200h200q17 0 28.5 11.5T760-480q0 17-11.5 28.5T720-440H520v200q0 17-11.5 28.5T480-200q-17 0-28.5-11.5T440-240v-200Z"
            />
          </template>
        </svg>
      </div>
    </button>
    <transition :enter-active-class="$style.slide_enter_active" :leave-active-class="$style.slide_leave_active">
      <div v-show="showContent">
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue"

type IconPattern = "arrow" | "plus-minus"

const props = withDefaults(
  defineProps<{
    icon?: IconPattern
    /** デフォルトを開いた状態にするか */
    isOpenByDefault?: boolean
  }>(),
  {
    icon: "arrow",
    isOpenByDefault: false,
  },
)

const showContent = ref(props.isOpenByDefault ?? false)
</script>

<style scoped module lang="scss">
.title_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $text-black;
  width: 100%;
  gap: 0.5rem;
  .icon {
    cursor: pointer;

    svg {
      height: 24px;
      width: 24px;

      fill: $primary-40;
      stroke: $primary-40;

      &:hover {
        fill: $primary-20;
        stroke: $primary-20;
      }
    }
  }
}

// アコーディオンの開閉時のアニメーション
@keyframes open {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.slide_enter_active {
  animation: open 0.2s;
}
.slide_leave_active {
  animation: open 0.2s linear reverse;
}
</style>
